$(function() {

    let investments = [
        ["Pre-seed", "[Stealth]", " "], // ["Pre-seed", "Arcube", "https://arcube.org"]
        ["Seed", "FinCrime Dynamics", "https://fincrimedynamics.com"], 
        ["Pre-seed", "Ergodic", "https://ergodic.ai"],
        ["Seed", "Ittybit", "https://ittybit.com"], 
        ["Seed", "HealthKey", "https://healthkey.health"], 
        ["Series A", "TurinTech", "https://turintech.ai"],
        ["Pre-seed", "Noggin HQ", "https://nogginhq.co.uk"],
        ["Angel", "Armada", "https://armada-technologies.com"],
        ["Angel", "[Stealth]", " "],
    ]
    let board = [
        ["Board", "FinCrime Dynamics", "https://fincrimedynamics.com"],
        ["Board", "HealthKey", "https://healthkey.health"],
        ["Board", "Noggin HQ", "https://nogginhq.co.uk"],
        ["Board", "HoxtonAI", "https://hoxton.ai"],
        
    ]
    let advisor = [
        ["Advisor", "AI Diagnostics", "https://aidmedical.care"],
        ["Advisor", "[Stealth]", " "] // ["Advisor", "LinkMyRide", "https://linkmyride.com"]
    ]

    $('body').terminal({
     help: function() {
         this.echo("Available commands:")
         this.echo("about")
         this.echo("portfolio")
         this.echo("linkedin")
         // this.echo("crunchbase")
         this.echo("email")
     },
     about: function() {
         this.echo("I'm a technical startup founder turned investor, advisor and mentor, based in Oxford, UK.")
     },
     linkedin: function() {
         this.echo("https://linkedin.com/in/richoakley")
     },
     email: function() {
         this.echo("hey@richoakley.com")
     },
     whoami: function() {
        this.echo("guest")
    },
    sudo: function(...args) {
        window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
    },
    rm: function(...args) {
        window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
    },
     crunchbase: function() {
         this.echo("https://www.crunchbase.com/person/richard-oakley-147b")
     },
     pitch: function() {
         this.echo("If you want to tell me about your startup, visit https://pitch.richoakley.com")
     },
     quit: function() {
         window.location.href = "/"
     },
     exit: function() {
        window.location.href = "/"
     },
     photo: function() {
        this.echo(photo.innerHTML)
     },
     investments: function() {
        var investments_output = JSON.parse(JSON.stringify(investments))
        // update investments_output so that the second column is appended with the value of column 1 in brackets
        for (var i = 0; i < investments_output.length; i++) {
            investments_output[i][1] = investments_output[i][1] + " (" + investments_output[i][0] + ")"
        }
        // drop the first column
        investments_output = investments_output.map(function(arr) {
            return arr.slice(1)
        }) 
        this.echo(ascii_table(investments_output))
     },
     portfolio: function() {
        // clone investments array
        var investments_output_2 = JSON.parse(JSON.stringify(investments))
        // update investments_output so that the first column is prepended with "Investor" with the value of the first column in brackets
        for (var i = 0; i < investments_output_2.length; i++) {
            investments_output_2[i][0] = "Investor (" + investments_output_2[i][0] + ")"
        }
        // merge arrays
        var content = investments_output_2.concat(board, advisor)
        this.echo(ascii_table(content))
     }
    }, {
   greetings: "👋 Type 'help' to get started",
   prompt: 'guest@richoakley.com$ ',
   checkArity: false
 });
 });